import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import TrendingUp from "@material-ui/icons/TrendingUp";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Info from "components/Typography/Info.js";
import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";
import Button from "components/CustomButtons/Button.js";

import caseStudiesStyle from "assets/jss/material-kit-pro-react/views/mainPage/caseStudiesStyle.js";

import cardBlog4 from "assets/img/examples/card-blog4.jpg";
import office2 from "assets/img/office2.jpg";
import blog5 from "assets/img/examples/blog5.jpg";
import blog6 from "assets/img/examples/blog6.jpg";
import blog7 from "assets/img/examples/blog7.jpg";
import blog8 from "assets/img/examples/blog8.jpg";
import bg5 from "assets/img/bg5.jpg";

import ffpo from "assets/img/wijja/ffpo.png"
import tracking from "assets/img/wijja/tracking.png"
import invoiceReminders from "assets/img/wijja/invoiceReminders.png"

import GetQuote from "components/Modal/GetQuote.js"

const useStyles = makeStyles(caseStudiesStyle);

export default function SectionCaseStudies({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      {/* Blogs 4 START */}
      <div className={classes.blog}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              <h2 className={classes.title}>Case Studies</h2>
              <Card plain blog className={classes.card4}>
                <CardHeader image plain>
                  <a href="https://leadfrompurpose.org/home" target="_blank">
                    <img src={ffpo} alt="..." />
                  </a>
                  <div
                    className={classes.coloredShadow}
                    style={{ backgroundImage: `url(${ffpo})`, opacity: "1" }}
                  />
                </CardHeader>
                <CardBody plain>
                  <Info>
                    <h6 className={classes.cardCategory}>WEB DESIGN</h6>
                  </Info>
                  <h3 className={classes.cardTitle}>
                    Full Featured React Websites
                  </h3>
                  <h5 className={classes.description}>
                    We've worked with our partners and the Foundation for Purposeful Organizations to create a full
                    featured website with a learning dashboard on the backend.
                  </h5>
                  <GetQuote />
                </CardBody>
              </Card>
              <Card plain blog className={classes.card4}>
                <CardHeader image plain>
                  <a href="https://gitlab.com/mpcahn/shipment-tracking-portfolio-item" target="_blank">
                    <img src={tracking} alt="..." />
                  </a>
                  <div
                    className={classes.coloredShadow}
                    style={{ backgroundImage: `url(${tracking})`, opacity: "1" }}
                  />
                </CardHeader>
                <CardBody plain>
                  <Success>
                    <h6 className={classes.cardCategory}>CUSTOM WEB APPLICATIONS</h6>
                  </Success>
                  <h3 className={classes.cardTitle}>
                    Asset Tracking Using Google Maps
                  </h3>
                  <h5 className={classes.description}>
                    We helped our clients better serve their customers by creating an asset tracking
                    application to allow customers to see where their shipment is in real time.
                  </h5>
                  <GetQuote />
                </CardBody>
              </Card>
              <Card plain blog className={classes.card4}>
                <CardHeader image plain>
                  <a href="https://gitlab.com/mpcahn/invoice-reminders-portfolio-item" target="_blank">
                    <img src={invoiceReminders} alt="..." />
                  </a>
                  <div
                    className={classes.coloredShadow}
                    style={{ backgroundImage: `url(${invoiceReminders})`, opacity: "1" }}
                  />
                </CardHeader>
                <CardBody plain>
                  <Info>
                    <h6 className={classes.cardCategory}>CUSTOM GSUITE APPS</h6>
                  </Info>
                  <h3 className={classes.cardTitle}>
                    Automate Business Processes with Gsuite
                  </h3>
                  <h5 className={classes.description}>
                    We helped our clients cut costs and increase revenue by automating key parts of their invoicing process.
                  </h5>
                  <GetQuote />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Blogs 4 END */}
    </div>
  );
}
