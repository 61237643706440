import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// import  from "@material-ui/icons/";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import Muted from "components/Typography/Muted.js";

import teamsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.js";

import bg7 from "assets/img/bg7.jpg";
import cardProfile1Square from "assets/img/wijja/profile.jpg";
import marco from "assets/img/wijja/Marco.jpg";
import KordspaceIcon from "assets/img/wijja/KordspaceIcon.jpg";

import GetQuote from "components/Modal/GetQuote.js"

const useStyles = makeStyles(teamsStyle);

export default function SectionTeams({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      <div
        className={classes.team + " " + classes.section}
        style={{ backgroundImage: `url(${bg7})` }}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title}>Team</h2>
              <h5 className={classes.descriptionWhite}>
                Our experts will help you assemble a team to make you're company ready to adapt to a rapidly changing business environment.
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer justify="center">
            <GridItem xs={12} sm={4} md={4}>
              <Card profile>
                <CardAvatar profile>
                  <a href="#pablo" onClick={e => e.preventDefault()}>
                    <img src={cardProfile1Square} alt="..." />
                  </a>
                </CardAvatar>
                <CardBody>
                  <h4 className={classes.cardTitle}>Matthew Cahn</h4>
                  <Muted>
                    <h6 className={classes.cardCategory}>CEO / Founder</h6>
                  </Muted>
                  <p className={classes.description}>
                    My diverse experience as a developer and agile coach will help give your organization the guidance and technology to adapt to a changing world.
                  </p>
                </CardBody>
                <CardFooter profile className={classes.justifyContent}>
                  <a href="https://www.linkedin.com/in/mpcahn/" target="_blank">
                    <Button justIcon round color="linkedin">
                      <i className="fab fa-linkedin-in" />
                    </Button>
                  </a>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card profile>
                <CardAvatar profile>
                  <a href="#pablo" onClick={e => e.preventDefault()}>
                    <img src={marco} alt="..." />
                  </a>
                </CardAvatar>
                <CardBody>
                  <h4 className={classes.cardTitle}>Marco Lopes</h4>
                  <Muted>
                    <h6 className={classes.cardCategory}>CEO / Founder Kord.Space</h6>
                  </Muted>
                  <p className={classes.description}>
                    Kordspace started as a small team of tech industry contractors and designers passionate about providing creative and competitive branding projects for smaller businesses.
                  </p>
                </CardBody>
                <CardFooter profile className={classes.justifyContent}>
                  <a href="https://www.linkedin.com/in/kordspace/" target="_blank">
                    <Button justIcon round color="linkedin">
                      <i className="fab fa-linkedin-in" />
                    </Button>
                  </a>
                  <a href="https://kord.space" target="_blank">
                    <Button justIcon round>
                      <img className={classes.icon} src={KordspaceIcon} alt="..." />
                    </Button>
                  </a>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem>
              <GetQuote source="team" />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
