import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import headersStyle from "assets/jss/material-kit-pro-react/views/mainPage/homeStyle.js";

import office2 from "assets/img/examples/office2.jpg";

import GetQuote from "components/Modal/GetQuote.js"

const useStyles = makeStyles(headersStyle);

export default function SectionHome({ ...rest }) {
  const classes = useStyles();
  
  return (
    // we've set the className to cd-section so we can make smooth scroll to it
    <div className="cd-section" {...rest}>               
      <div>        
        <div
          className={classes.pageHeader}
          style={{ backgroundImage: `url("${office2}")` }}
        >
          <div className={classes.conatinerHeader2}>
            <GridContainer>
              <GridItem
                xs={12}
                sm={8}
                md={8}
                className={classNames(
                  classes.mlAuto,
                  classes.mrAuto,
                  classes.textCenter
                )}
              >
                <h2 className={classes.title}>Skillful Solutions for a Changing World</h2>
                <h4>
                  Technology is rapidly changing the business landscape. We help organizations 
                  modify and build their business processes around their customers and employees 
                  to help businesses adapt with agility to thrive in a world of changing conditions. 
                </h4>
                <GetQuote source="home" />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </div>
  );
}
