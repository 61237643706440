import React from "react";
import axios from "axios";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Mail from "@material-ui/icons/Mail";
import Face from "@material-ui/icons/Face";

// core components
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import style from "assets/jss/material-kit-pro-react/components/getQuoteStyles.js";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(style);

export default function GetQuote(props) {
    const [getQuoteModal, setGetQuoteModal] = React.useState(false);
    const [quoteSent, setQuoteSent] = React.useState(false)
    const [values, setValues] = React.useState({
        name: '',
        email: '',
        business: '',
        referral: ''
    })
    const handleChange = (e) => {
        e.persist()
        setValues((values) => ({
            ...values,
            [e.target.id]: e.target.value
        }))
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        let formId = "1FAIpQLSeSlLwLxALemOK0U009lfdGBf8KU9Sjcr_qSGAqUtuWO-ZIUw"
        let url = `https://docs.google.com/forms/d/e/${formId}/formResponse?usp=pp_url`
        url += `&entry.1388619827=${values.name}`
        url += `&entry.1770730664=${values.email}`
        url += `&entry.595948314=${values.business}`
        url += `&entry.604441105=${values.referral}`
        url += `&entry.1449848298=${props.source}`

        //Returns an error but the form is sent anyway. Google Forms is a bit janky
        axios.get(
            url,
            {
                data: {},
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        ).then(res => console.log(res)).catch((err) => {
            console.log(err)
            setQuoteSent(true)
        })
    }
    const classes = useStyles();
    return (
        <div style={{ textAlign: "center" }}>
            <Button
                color="primary"
                onClick={() => setGetQuoteModal(true)}
            >Get a Quote!</Button>
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={getQuoteModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setGetQuoteModal(false)}
            >
                <Card plain className={classes.modalLoginCard}>
                    <DialogTitle
                        disableTypography
                        className={classes.modalHeader}
                    >
                        <CardHeader
                            color="primary"
                            className={`${classes.textCenter}`}
                        >
                            <h3 className={classes.cardTitleWhite}>Get A Quote</h3>
                        </CardHeader>
                    </DialogTitle>

                    {quoteSent ? (
                        <p className={`${classes.description} ${classes.textCenter}`}><br />
                            Quote Request Sent! A customer service rep will be in touch soon! <br />
                        </p>
                    ) : (
                            <form onSubmit={handleSubmit}>
                                <DialogContent
                                    className={classes.modalBody}
                                >
                                    <p className={`${classes.description} ${classes.textCenter}`}>
                                        Fill out the form and a customer service representative will contact you.
                            </p>
                                    <CardBody className={classes.cardLoginBody}>
                                        <CustomInput
                                            id="name"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                placeholder: "Name...",
                                                required: true,
                                                value: values.name,
                                                onChange: handleChange
                                            }}
                                        />
                                        <CustomInput
                                            id="email"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                placeholder: "Email...",
                                                type: "email",
                                                required: true,
                                                value: values.email,
                                                onChange: handleChange
                                            }}
                                        />
                                        <CustomInput
                                            id="business"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                placeholder: "Tell us about your business needs...",
                                                required: true,
                                                multiline: true,
                                                value: values.business,
                                                onChange: handleChange
                                            }}
                                        />
                                        <CustomInput
                                            id="referral"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                placeholder: "Where did you hear about us?",
                                                value: values.referral,
                                                onChange: handleChange
                                            }}
                                        />
                                    </CardBody>
                                </DialogContent>
                                <DialogActions
                                    className={`${classes.modalFooter} ${classes.justifyContentCenter}`}
                                >
                                    <Button color="primary" simple size="lg" type="submit">
                                        Get Quote
                        </Button>
                                </DialogActions>
                            </form>

                        )}
                    </Card>
            </Dialog>
        </div>
    );
}