import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Build from "@material-ui/icons/Build";
import Code from "@material-ui/icons/Code";
import Dashboard from "@material-ui/icons/Dashboard";
import Search from "@material-ui/icons/Search";
import TodayIcon from '@material-ui/icons/Today';
import ViewCarousel from "@material-ui/icons/ViewCarousel";
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import featuresStyle from "assets/jss/material-kit-pro-react/views/mainPage/featuresStyle.js";

import bg9 from "assets/img/bg9.jpg";

import GetQuote from "components/Modal/GetQuote.js"

const useStyles = makeStyles(featuresStyle);

export default function SectionFeatures({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      <div className={classes.container}>
        {/* BENEFITS */}
        <div className={classes.features2}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title}>Adapt and Thrive</h2>
              <h5 className={classes.description}>
                Our business and technology services are used to help businesses grow by achieving clarity
                of where they want to go, streamline business processes, and generating more revenue.
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                icon={Search}
                title="Clarify"
                description={
                  <span>
                    <p>
                      Use Agile and Lean business methods to clarify high level business processes and goals and how to implement systems and technology to achieve them.
                    </p>
                  </span>
                }
                iconColor="info"
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                icon={Build}
                title="Streamline"
                description={
                  <span>
                    <p>
                      Our expertise allows us to take full advantage of the technologies you are already paying for.
                    </p>
                  </span>
                }
                iconColor="danger"
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                icon={Code}
                title="Build"
                description={
                  <span>
                    <p>
                      We can build custom web and technology services to take your business to the next level.
                    </p>
                  </span>
                }
                iconColor="success"
              />
            </GridItem>
            <GridItem>
              <GetQuote source="benefits" />
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* FEATURES */}
      <div
        className={classes.features5}
        style={{ backgroundImage: `url(${bg9})` }}
      >
        <GridContainer>
          <GridItem
            xs={12}
            sm={8}
            md={8}
            className={
              classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
            }
          >
            <h2 className={classes.title}>Our Expertise</h2>
          </GridItem>
          <div className={classes.container}>
            <GridContainer className={classes.gridContainer}>
              <GridItem xs={12} sm={4} className={classes.gridItem}>
                <InfoArea
                  vertical
                  className={classes.infoArea5}
                  icon={YoutubeSearchedForIcon}
                  title="Agile"
                  description={
                    <p>
                      We use Agile Methodologies to satisfy your business needs
                      through early and continuous delivery of deliverables.
                    </p>
                  }
                  iconColor="info"
                />
              </GridItem>
              <GridItem xs={12} sm={4} className={classes.gridItem}>
                <InfoArea
                  vertical
                  className={classes.infoArea5}
                  icon={AutorenewIcon}
                  title="Lean"
                  description={
                    <p>
                      We use lean principles to teach our clients how to steer,
                      when to turn, and when to persevere to grow your business
                    </p>
                  }
                  iconColor="danger"
                />
              </GridItem>
              <GridItem xs={12} sm={4} className={classes.gridItem}>
                <InfoArea
                  vertical
                  className={classes.infoArea5}
                  icon={TodayIcon}
                  title="G Suite"
                  description={
                    <p>
                      We train you on how to take the best advantage of the
                      tools you're already paying for such as G Suite.
                    </p>
                  }
                  iconColor="primary"
                />
              </GridItem>
            </GridContainer>
            <GridContainer className={classes.gridContainer}>
              <GridItem xs={12} sm={4} className={classes.gridItem}>
                <InfoArea
                  vertical
                  className={classes.infoArea5}
                  icon={Code}
                  title="Apps Scripting"
                  description={
                    <p>
                      We can deliver custom scripts within google to further
                      customize your utilization of google apps.
                    </p>
                  }
                />
              </GridItem>
              <GridItem xs={12} sm={4} className={classes.gridItem}>
                <InfoArea
                  vertical
                  className={classes.infoArea5}
                  icon={ViewCarousel}
                  title="Custom Web Sites"
                  description={
                    <p>
                      We can deliver a custom React website to wow your customers.
                    </p>
                  }
                />
                <GridItem>
                  <GetQuote source="features" />
                </GridItem>
              </GridItem>
              <GridItem xs={12} sm={4} className={classes.gridItem}>
                <InfoArea
                  vertical
                  className={classes.infoArea5}
                  icon={Dashboard}
                  title="Custom Dashboard"
                  description={
                    <p>
                      We deliver custom dashboards to help our clients deliver value
                      to their customers and further streamline business processes.
                    </p>
                  }
                />
              </GridItem>
            </GridContainer>
          </div>
        </GridContainer>
      </div>
    </div>
  );
}
